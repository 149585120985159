import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../templates/layouts/loader/Loadable';

/*****Routes******/
const LoginFormik = Loadable(lazy(() => import('../templates/LoginFormik')));
const ActivityLoginFormik = Loadable(lazy(() => import('../templates/activityLoginFormik')));
const Error = Loadable(lazy(() => import('../templates/Error')));
const NotAuth = Loadable(lazy(() => import('../templates/NotAuth')));
const NotAuth_activtitySys = Loadable(lazy(() => import('../templates/NotAuth_activtitySys')));

const Activity = Loadable(lazy(() => import('../templates/Activity')));
const LineBotSettings = Loadable(lazy(() => import('../templates/LineBotSettings')));
const LineBotNotifications = Loadable(lazy(() => import('../templates/LineBotNotifications')));
const LineBotBindingList = Loadable(lazy(() => import('../templates/LineBotBindingList')));

const DistributorPage = Loadable(lazy(() => import('../templates/DistributorPage')));
const DistributorPageCharts = Loadable(lazy(() => import('../templates/DistributorPageCharts')));
const DistributorPageQrcode = Loadable(lazy(() => import('../templates/DistributorPageQrcode')));

const ActivitySysPage = Loadable(lazy(() => import('../templates/ActivitySysPage')));
const ActivitySysPageDistributor = Loadable(lazy(() => import('../templates/ActivitySysPageDistributor')));
const ActivitySysPageCustomer = Loadable(lazy(() => import('../templates/ActivitySysPageCustomer')));
const ActivitySysPageStoredValue = Loadable(lazy(() => import('../templates/ActivitySysPageStoredValue')));

/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../templates/layouts/FullLayout')));
const FullLayout_distributor = Loadable(lazy(() => import('../templates/layouts/FullLayout_distributor')));
const FullLayout_activitySys = Loadable(lazy(() => import('../templates/layouts/FullLayout_activitySys')));
const BlankLayout = Loadable(lazy(() => import('../templates/layouts/BlankLayout')));


const ThemeRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', name: 'Home', element: <Navigate to="/Login" /> },
      { path: '/activity', name: '儲值活動列表', exact: true, element: <Activity /> },
      { path: '/LineBot/LineBotSettings', name: 'LineBot', exact: true, element: <LineBotSettings /> },
      { path: '/LineBot/LineBotNotifications', name: 'LineBot', exact: true, element: <LineBotNotifications /> },
      { path: '/LineBot/LineBotBindingList', name: 'LineBot', exact: true, element: <LineBotBindingList /> }
    ]
  },
  {
    path: '/',
    element: <FullLayout_distributor />,
    children: [
      { path: '/distributorPage/:distributor_uuid', name: '金流明細', exact: true, element: <DistributorPage /> },
      { path: '/distributorPage/charts/:distributor_uuid', name: '收支報表', exact: true, element: <DistributorPageCharts/> },
      { path: '/distributorPage/qrcode/:distributor_uuid', name: '收支報表', exact: true, element: <DistributorPageQrcode/> },
    ]
  },
  {
    path: '/',
    element: <FullLayout_activitySys />,
    children: [
      { path: '/activitySysPage/home/:activitySys_uuid', name: '首頁', exact: true, element: <ActivitySysPage /> },
      { path: '/activitySysPage/distributor/:activitySys_uuid', name: '店家管理', exact: true, element: <ActivitySysPageDistributor/> },
      { path: '/activitySysPage/customer/:activitySys_uuid', name: '顧客管理', exact: true, element: <ActivitySysPageCustomer/> },
      { path: '/activitySysPage/storedvalue/:activitySys_uuid', name: '儲值系統', exact: true, element: <ActivitySysPageStoredValue/> },
    ]
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      
      { path: '404', element: <Error /> },
      { path: 'NotAuth', element: <NotAuth /> },
      { path: 'NotAuth_activtitySys', element: <NotAuth_activtitySys /> },
      { path: '*', element: <Error /> },   
      { path: 'Login', element: <LoginFormik /> },
      { path: 'activityLogin/:activity_uuid', element: <ActivityLoginFormik /> },
    ],
  },
]

export default ThemeRoutes;
